/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Pinterest event listeners
 * */

// Pixel load event listener
jQuery(document).on("pmw:load-pixels", () => {

	if (wpmDataLayer?.pixels?.pinterest?.pixel_id && !wpmDataLayer?.pixels?.pinterest?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "Pinterest")) wpm.loadPinterestPixel()
	}
})

// https://help.pinterest.com/en/business/article/add-event-codes
// AddToCart event
jQuery(document).on("pmw:s2s:add-to-cart", (event, payload) => {

	const product = payload.product

	try {
		if (wpm.canNotFirePinterestEvent()) return

		const data = {
			event_id  : payload.pinterest.event_id,
			value     : parseFloat(product.quantity * product.price),
			currency  : product.currency,
			line_items: [wpm.getPinterestProductData(product)],
		}

		// keep "addtocart" as this is the official browser event name
		pintrk("track", "addtocart", data)
		pmw.console.log("Pinterest Pixel: AddToCart event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// https://help.pinterest.com/en/business/article/add-event-codes
// pageview event
jQuery(document).on("pmw:s2s:view-item", (event, payload) => {

	const product = payload.product

	try {
		if (wpm.canNotFirePinterestEvent()) return

		let data = {
			event_id: payload.pinterest.event_id,
		}

		if (product) {
			data.currency   = product.currency
			data.line_items = [wpm.getPinterestProductData(product)]
		}

		pintrk("track", "pagevisit", data)
		pmw.console.log("Pinterest Pixel: pagevisit event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// view search event
jQuery(document).on("pmw:s2s:search", (event, payload) => {

	try {
		if (wpm.canNotFirePinterestEvent()) return

		let urlParams = new URLSearchParams(window.location.search)

		const data = {
			event_id    : payload.pinterest.event_id,
			search_query: urlParams.get("s"),
		}

		pintrk("track", "search", data)
		pmw.console.log("Pinterest Pixel: search event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// view category event
jQuery(document).on("pmw:s2s:view-category", (event, payload) => {

	try {
		if (wpm.canNotFirePinterestEvent()) return

		const data = {
			event_id: payload.pinterest.event_id,
		}

		pintrk("track", "viewcategory", data)
		pmw.console.log("Pinterest Pixel: viewcategory event sent", data)

	} catch (e) {
		console.error(e)
	}
})


// view order received page event
// https://developers.pinterest.com/docs/tag/conversion/
jQuery(document).on("pmw:s2s:view-order-received-page", (event, payload) => {

	try {
		if (wpm.canNotFirePinterestEvent()) return

		const data = {
			event_id      : wpmDataLayer.order.id.toString(),
			value         : wpmDataLayer.order.value.marketing,
			order_quantity: wpmDataLayer.order.quantity,
			currency      : wpmDataLayer.order.currency,
			order_id      : wpmDataLayer.order.id.toString(),
			line_items    : wpm.pinterestFormattedOrderItems(),
		}

		pintrk("track", "checkout", data)
		pmw.console.log("Pinterest Pixel: checkout event sent", data)

	} catch (e) {
		console.error(e)
	}
})
