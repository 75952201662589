/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Source: https://www.outbrain.com/help/advertisers/dynamic-values/
 *
 * All event listeners
 * */

// Pixel load event listener
jQuery(document).on("pmw:load-pixels", () => {

	if (wpmDataLayer?.pixels?.outbrain?.advertiser_id && !wpmDataLayer?.pixels?.outbrain?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "Outbrain")) wpm.loadOutbrainPixel()
	}
})

// view search event
jQuery(document).on("pmw:search", () => {

	try {
		if(wpm.canNotFireOutbrainEvent()) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.search)
		pmw.console.log("Outbrain: search event sent")

	} catch (e) {
		console.error(e)
	}
})

// ViewContent event
jQuery(document).on("pmw:view-item", (event, product = null) => {

	try {
		if(wpm.canNotFireOutbrainEvent()) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.view_content)
		pmw.console.log("Outbrain: view_content event sent")

	} catch (e) {
		console.error(e)
	}
})

// AddToCart event
jQuery(document).on("pmw:add-to-cart", (event, product) => {

	try {
		if(wpm.canNotFireOutbrainEvent()) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.add_to_cart)
		pmw.console.log("Outbrain: add_to_cart event sent")

	} catch (e) {
		console.error(e)
	}
})

// begin_checkout event
jQuery(document).on("pmw:begin-checkout", () => {

	try {
		if(wpm.canNotFireOutbrainEvent()) return

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.start_checkout)
		pmw.console.log("Outbrain: start_checkout event sent")

	} catch (e) {
		console.error(e)
	}
})

// view order received page event
// https://www.outbrain.com/help/advertisers/dynamic-values/
jQuery(document).on("pmw:view-order-received-page", () => {

	try {
		if(wpm.canNotFireOutbrainEvent()) return

		const data = {
			orderValue: String(wpmDataLayer.order.value.marketing),
			currency  : wpmDataLayer.order.currency,
			orderId   : String(wpmDataLayer.order.id),
		}

		obApi("track", wpmDataLayer.pixels.outbrain.event_names.purchase, data)
		pmw.console.log("Outbrain: purchase event sent", data)

	} catch (e) {
		console.error(e)
	}
})
