/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Automatic Conversion Recovery (ACR)
 */
jQuery(document).on("pmw:load-always", async () => {

	try {
		// Return if on purchase confirmation page
		if (wpmDataLayer?.shop?.page_type === "order_received_page") return

		pmw.console.log("ACR check running")

		// Return if ACR cookie has not been set
		if (!wpm.getCookie("pmw_automatic_conversion_recovery")) return

		pmw.console.log("ACR cookie found")

		if (wpm.retrieveData("acr_attempted")) return

		pmw.console.log("ACR check not run in this session")

		// Get the order details from the server
		await wpm.getAcrOrderDetails()

		if (!wpmDataLayer?.order) {
			pmw.console.log("Pixel Manager: ACR order details not retrieved")
			wpm.storeData("acr_attempted", true)
			return
		}

		pmw.console.log("ACR order details retrieved")
		pmw.console.log("wpmDataLayer.order", wpmDataLayer.order)

		// Fire the conversion pixels
		jQuery(document).trigger("pmw:view-order-received-page")

		// Save the order ID on the server to prevent duplicate conversions
		wpm.writeOrderIdToStorage(wpmDataLayer.order.id, wpmDataLayer.order.key, "acr_full_auto")

		// Delete the ACR cookie
		wpm.deleteCookie("pmw_automatic_conversion_recovery")

	} catch (error) {
		wpm.storeData("acr_attempted", true)
		pmw.console.error(error)
	}
})

/**
 * Scroll Tracker
 */

// We only can load it once the pmwDataLayer is available
jQuery(document).on("wpmLoad", function () {

	// Only initialize if the scroll tracker is enabled
	if (!wpmDataLayer?.general?.scroll_tracking_thresholds || !wpmDataLayer.general.scroll_tracking_thresholds.length > 0) return

	(function (wpm, $, undefined) {

		wpm.scrollVisibilityThreshold = wpmDataLayer.general.scroll_tracking_thresholds

		// Create an object that contains each key from visibilityBreakPoints and sets each value to false
		wpm.scrollVisibilityThresholdStatus = wpm.scrollVisibilityThreshold.reduce((obj, item) => {
			obj[item] = false
			return obj
		}, {})

	}(window.wpm = window.wpm || {}, jQuery))

	/**
	 * Horizontal Scroll Tracking
	 */
	jQuery(window).on("scroll", (event) => {

		const scrollPosition = jQuery(window).scrollTop()

		// If the scroll reaches a visibility threshold for each threshold in visibilityThresholdStatus that is set to false, trigger an event
		for (const [threshold, value] of Object.entries(wpm.scrollVisibilityThresholdStatus)) {
			if (value === false) {
				const visibilityBreakPoint = (jQuery(document).height() - jQuery(window).height()) * (threshold / 100)
				if (scrollPosition >= visibilityBreakPoint) {
					jQuery(document).trigger("pmw:scroll-depth", {
						direction   : "vertical",
						threshold   : threshold,
						position    : scrollPosition,
						positionUnit: "pixels",
					})
					wpm.scrollVisibilityThresholdStatus[threshold] = true
				}
			}
		}
	})

})


/**
 * Send transient data to the server
 *
 * Currently we only trigger it after a page is loaded.
 * We don't trigger it on button clicks or so because those can
 * lead to a page reload
 * and we don't want to add extra load to the server on page loads.
 *
 * Such as the referrer, gbraid, wbraid, etc.
 */
jQuery(document).on("wpmLoad", () => {

	// Abort if no WooCommerce session is active
	if (wpm.isNotInWooCommerceSesssion()) return

	// Abort if not on a cart or checkout page
	if (!["cart", "checkout"].includes(wpmDataLayer?.shop?.page_type)) return

	let payload = {}

	// If the referrer is stored, add it to the payload
	let referrer = wpm.retrieveData("referrer")
	if (referrer) payload.referrer = referrer

	let gclid = wpm.retrieveData("gclid")
	if (gclid) payload.gclid = gclid

	// If the wbraid is stored, add it to the payload
	let wbraid = wpm.retrieveData("wbraid")
	if (wbraid) payload.wbraid = wbraid

	// If the gbraid is stored, add it to the payload
	let gbraid = wpm.retrieveData("gbraid")
	if (gbraid) payload.gbraid = gbraid

	let fbclid = wpm.retrieveData("fbclid")
	if (fbclid) payload.fbclid = fbclid

	let ttclid = wpm.retrieveData("ttclid")
	if (ttclid) payload.ttclid = ttclid

	let scid = wpm.retrieveData("scid")
	if (scid) payload.scid = scid

	let epik = wpm.retrieveData("epik")
	if (epik) payload.epik = epik

	// Abort if the payload is empty
	if (jQuery.isEmptyObject(payload)) return

	wpm.sendTransientSessionIdentifiers(payload)
})
