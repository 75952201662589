/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Adroll premium functions
 * */

(function (wpm, $, undefined) {

	wpm.load_adroll_pixel = () => {

		try {
			wpmDataLayer.pixels.adroll.loaded = true

			// @formatter:off

			adroll_adv_id  = wpmDataLayer.pixels.adroll.advertiser_id;
			adroll_pix_id  = wpmDataLayer.pixels.adroll.pixel_id;
			adroll_version = "2.0";

			(function(w, d, e, o, a) {
				w.__adroll_loaded = true;
				w.adroll = w.adroll || [];
				w.adroll.f = [ 'setProperties', 'identify', 'track' ];
				var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js";
				for (a = 0; a < w.adroll.f.length; a++) {
					w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
						return function() {
							w.adroll.push([ n, arguments ])
						}
					})(w.adroll.f[a])
				}
				e = d.createElement('script');
				o = d.getElementsByTagName('script')[0];
				e.async = 1;
				e.src = roundtripUrl;
				o.parentNode.insertBefore(e, o);
			})(window, document);

			adroll.track("pageView");

			// @formatter:on

		} catch (e) {
			console.error(e)
		}
	}

	wpm.adroll_purchase_items = () => {

		let orderItems = []

		Object.values(wpmDataLayer.order.items).forEach(item => {

			let orderItem = {
				quantity: item.quantity,
				price   : item.price,
				// product_group: "abc", // Currently not supported by the Pixel Manager. Would need a custom solution.
				// category: item.category,  // Not sure yet how to handle, since WC can have multiple categories per product.
			}

			if (wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id) {

				orderItem.product_id = String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.adroll.dynamic_remarketing.id_type])
				orderItems.push(orderItem)
			} else {

				orderItem.product_id = String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.adroll.dynamic_remarketing.id_type])
				orderItems.push(orderItem)
			}
		})

		return orderItems
	}

	/**
	 * Check if the Adroll event can be fired
	 *
	 * @return {boolean}
	 */
	wpm.canFireAdrollEvent = () => {

		if (!wpmDataLayer?.pixels?.adroll?.loaded) return false

		// If the user has not given consent for marketing, return false
		if (!wpm.consent.categories.get().marketing) return false

		return true
	}

	/**
	 * Check if the Adroll event can not be fired
	 *
	 * @return {boolean}
	 */
	wpm.canNotFireAdrollEvent = () => {
		return !wpm.canFireAdrollEvent()
	}

}(window.wpm = window.wpm || {}, jQuery));
