/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Google base premium functions
 */

(function (wpm, $, undefined) {


}(window.wpm = window.wpm || {}, jQuery))
