/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Snapchat Ads functions
 * */

(function (wpm, $, undefined) {

	// https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
	wpm.snapchatGetUserInfo = () => {

		if (!wpmDataLayer?.pixels?.snapchat?.advanced_matching) {
			return {}
		}

		let userInfo = {}

		if (wpmDataLayer?.user?.email?.sha256) {
			userInfo.user_hashed_email = wpmDataLayer.user.email.sha256
		}

		if (wpmDataLayer?.user?.phone?.sha256?.snapchat) {
			userInfo.user_hashed_phone_number = wpmDataLayer.user.phone.sha256.snapchat
		}

		if (wpmDataLayer?.user?.first_name?.raw) {
			userInfo.firstname = wpmDataLayer.user.first_name.raw
		}

		if (wpmDataLayer?.user?.last_name?.raw) {
			userInfo.lastname = wpmDataLayer.user.last_name.raw
		}

		if (wpmDataLayer?.user?.city?.raw) {
			userInfo.geo_city = wpmDataLayer.user.city.raw
		}

		if (wpmDataLayer?.user?.state?.raw) {
			userInfo.geo_region = wpmDataLayer.user.state.raw
		}

		if (wpmDataLayer?.user?.postcode?.raw) {
			userInfo.geo_postal_code = wpmDataLayer.user.postcode.raw
		}

		if (wpmDataLayer?.user?.country?.raw) {
			userInfo.geo_country = wpmDataLayer.user.country.raw
		}

		return userInfo
	}

	wpm.loadSnapchatPixel = () => {

		try {
			wpmDataLayer.pixels.snapchat.loaded = true;

			// @formatter:off
			(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
			{a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
				a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
				r.src=n;var u=t.getElementsByTagName(s)[0];
				u.parentNode.insertBefore(r,u);})(window,document,
				'https://sc-static.net/scevent.min.js');

			snaptr("init", wpmDataLayer.pixels.snapchat.pixel_id, wpm.snapchatGetUserInfo());

			snaptr("track", "PAGE_VIEW")
			// @formatter:on


		} catch (e) {
			console.error(e)
		}
	}

	wpm.getSnapchatOrderItemIds = () => {
		let contentIds = []

		Object.values(wpmDataLayer.order.items).forEach(item => {

			let product

			if (wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id) {
				product = wpmDataLayer.products[item.variation_id]
			} else {
				product = wpmDataLayer.products[item.id]
			}

			contentIds.push(wpm.getProductIdForSpecificPixel(product, "snapchat"))
		})

		return contentIds
	}

	/**
	 * Fire Snapchat event
	 *
	 * @return {boolean}
	 */
	wpm.canFireSnapchatEvent = () => {
		if (!wpmDataLayer?.pixels?.snapchat?.loaded) return false

		if (!wpm.consent.categories.get().marketing) return false

		return true
	}

	/**
	 * Can not fire Snapchat event
	 *
	 * @return {boolean}
	 */
	wpm.canNotFireSnapchatEvent = () => {
		return !wpm.canFireSnapchatEvent()
	}

	/**
	 * Get Snapchat user data
	 * https://docs.snap.com/api/marketing-api/Conversions-API/Parameters#user-data-parameters
	 *
	 * @return {{}}
	 */
	wpm.getSnapchatUserData = () => {
		let data = {}

		// add user_agent from browser
		data.client_user_agent = navigator.userAgent

		// add sc_click_id
		if (snapchatGetScCid()) {
			data.sc_click_id = snapchatGetScCid()
		}

		// add sc_cookie1
		if (wpm.getCookie("_scid")) {
			// data.sc_cookie1 = wpm.getCookie("_scid")
			// data.uuid_c1 = wpm.getCookie("_scid")
			data.sc_click_id = wpm.getCookie("_scid")
		}

		if (wpmDataLayer?.pixels?.snapchat?.advanced_matching) {

			if (wpmDataLayer?.user?.email?.sha256) {
				data.em = wpmDataLayer.user.email.sha256
			}

			if (wpmDataLayer?.user?.phone?.snapchat) {
				data.ph = wpmDataLayer.user.phone.snapchat
			}

			// add first name
			if (wpmDataLayer?.user?.first_name?.snapchat) {
				data.fn = wpmDataLayer.user.first_name.snapchat
			}

			// add last name
			if (wpmDataLayer?.user?.last_name?.snapchat) {
				data.ln = wpmDataLayer.user.last_name.snapchat
			}

			// add city
			if (wpmDataLayer?.user?.city?.snapchat) {
				data.ct = wpmDataLayer.user.city.snapchat
			}

			// add state
			if (wpmDataLayer?.user?.state?.snapchat) {
				data.st = wpmDataLayer.user.state.snapchat
			}

			// add postal code
			if (wpmDataLayer?.user?.postal_code?.sha256) {
				data.zp = wpmDataLayer.user.postal_code.sha256
			}

			// add country
			if (wpmDataLayer?.user?.country?.snapchat) {
				data.country = wpmDataLayer.user.country.snapchat
			}

			// external_id
			if (wpmDataLayer?.user?.id?.sha256) {
				data.external_id = wpmDataLayer.user.id.sha256
			}
		}

		return data
	}

	const snapchatGetScCid = () => {

		// if the ScCid parameter is present in the URL get the value from there,
		// save it in the storage and return it

		// get all URL parameters

		let urlParams = new URLSearchParams(window.location.search)

		if (urlParams.has("ScCid")) {
			let scCid = urlParams.get("ScCid")
			wpm.storeData("snapchat_sc_cid", scCid)
			return scCid
		}

		// if the ScCid parameter is not present in the URL check if it is in the storage
		// and return it
		let scCid = wpm.retrieveData("snapchat_sc_cid")

		if (scCid) {
			return scCid
		}
	}

	wpm.getSnapchatS2SBaseData = () => {

		return {
			event_id: wpm.getRandomEventId(),
			// client_dedup_id : wpm.getRandomEventId(),
			event_time      : Math.floor(Date.now() / 1000), // event_time in seconds
			event_source_url: window.location.href,
			action_source   : "WEB",
			user_data       : wpm.getSnapchatUserData(),
			// integration     : "pmw",
		}
	}

	wpm.getSnapchatCustomDataForProduct = (product) => {
		return {
			content_category: product.category,
			content_ids     : [wpm.getProductIdForSpecificPixel(product, "snapchat")],
			content_name    : product.name,
			content_type    : "product",
			contents        : [{
				id        : wpm.getProductIdForSpecificPixel(product, "snapchat"),
				quantity  : product.quantity,
				item_price: product.price,
			}],
			currency        : product.currency,
			num_items       : product.quantity,
			value           : product.price * product.quantity,
		}
	}

	// wpm.getSnapchatCustomDataForCart = () => {
	//
	// 	let data = {
	// 		currency    : wpmDataLayer.shop.currency,
	// 		content_type: "product",
	// 		num_items   : 0,
	// 		content_ids : [],
	// 		brands      : [],
	// 		contents    : [],
	// 	}
	//
	// 	Object.values(wpmDataLayer.cart).forEach((item) => {
	//
	// 		// Count up num_items with each item iteration
	// 		data.num_items += item.quantity
	//
	// 		// Add the item IDs to the content_ids array
	// 		data.content_ids.push(wpm.getProductIdForSpecificPixel(item, "snapchat"))
	//
	// 		// Add the brand name to the brands array,
	// 		// if it is not already in the array
	// 		if (item.brand && !data.brands.includes(item.brand)) {
	// 			data.brands.push(item.brand)
	// 		}
	//
	// 		// contents is an array of objects.
	// 		// Each object represents a product in the cart with ID, quantity and item_price
	// 		data.contents.push({
	// 			id        : wpm.getProductIdForSpecificPixel(item, "snapchat"),
	// 			quantity  : item.quantity,
	// 			item_price: item.price,
	// 		})
	// 	})
	//
	// 	return data
	// }

}(window.wpm = window.wpm || {}, jQuery));
