/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Add functions for Facebook (pro)
 * */

(function (wpm, $, undefined) {


}(window.wpm = window.wpm || {}, jQuery))
