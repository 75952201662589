/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load Adroll event listeners
 *
 * https://help.adroll.com/hc/en-us/articles/360040116252-Connect-AdRoll-to-your-site-by-installing-the-AdRoll-Pixel
 *
 * */

// Pixel load event listener
jQuery(document).on("pmw:load-pixels", () => {

	if (
		wpmDataLayer?.pixels?.adroll?.advertiser_id
		&& wpmDataLayer?.pixels?.adroll?.pixel_id
		&& !wpmDataLayer?.pixels?.adroll?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "Adroll")) wpm.load_adroll_pixel()
	}
})

// view search event
// https://gist.github.com/dennismarlowe/c6826c360d34d9cf5ba7f7011e8a9741#file-product-search
jQuery(document).on("pmw:search", () => {

	try {
		if (wpm.canNotFireAdrollEvent()) return

		const data = {
			keywords: wpm.getSearchTermFromUrl(),
			// "products": [...]
		}

		window.adroll.track("productSearch", data)
		pmw.console.log("Adroll: productSearch event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// homeView event
// https://gist.github.com/dennismarlowe/59a5e7c7635d54ca36c4c0dd8d34dd70#file-home-view
jQuery(document).on("pmw:everywhere-else", () => {

	try {
		if (wpm.canNotFireAdrollEvent()) return
		if (wpmDataLayer?.shop?.page_type !== "front_page") return

		// window.adroll.track('homeView', {siteType: 'm'}) // not sure what siteType is. undocumented.
		window.adroll.track("homeView")
		pmw.console.log("Adroll: homeView event sent")

	} catch (e) {
		console.error(e)
	}
})

// view product event
// https://gist.github.com/dennismarlowe/6ac4fc4b5d0b228f59683135dcdec51b#file-capturing-product-information
jQuery(document).on("pmw:view-item", (event, product = null) => {

	try {
		if (wpm.canNotFireAdrollEvent()) return

		let data = {}

		if (product) {
			data.product_id = product.dyn_r_ids[wpmDataLayer.pixels.adroll.dynamic_remarketing.id_type]
			// data.product_group = 123
		}

		window.adroll.track("productView", data)
		pmw.console.log("Adroll: productView event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// add-to-cart event
// https://gist.github.com/dennismarlowe/1fede6dfba0d1243072b32aa23413537#file-add-to-cart
jQuery(document).on("pmw:add-to-cart", (event, product) => {

	try {
		if (wpm.canNotFireAdrollEvent()) return

		const data = {
			currency: product.currency,
			products: [{
				product_id: product.dyn_r_ids[wpmDataLayer.pixels.adroll.dynamic_remarketing.id_type],
				quantity  : product.quantity,
				price     : product.price,
				// product_group: "abc",
				// category: "abc"
			}],
		}

		window.adroll.track("addToCart", data)
		pmw.console.log("Adroll: addToCart event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// View order received page event
// https://gist.github.com/dennismarlowe/090c96c6629d5ca2056a8394cc060c79#file-purchase
jQuery(document).on("pmw:view-order-received-page", () => {

	try {
		if (wpm.canNotFireAdrollEvent()) return

		const data = {
			order_id        : wpmDataLayer.order.id,
			currency        : wpmDataLayer.order.currency,
			conversion_value: wpmDataLayer.order.value.marketing,
			products        : wpm.adroll_purchase_items(),
		}

		window.adroll.track("purchase", data)
		pmw.console.log("Adroll: purchase event sent", data)

	} catch (e) {
		console.error(e)
	}
})

