// TODO check all events and add more if there are any

/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * All event listeners
 *
 * Source: https://businesshelp.snapchat.com/s/article/pixel-direct-implementation
 * */

// Pixel load event listener
jQuery(document).on("pmw:load-pixels", () => {

	if (wpmDataLayer?.pixels?.snapchat?.pixel_id && !wpmDataLayer?.pixels?.snapchat?.loaded) {
		if (wpm.consent.canPixelBeFired("marketing", "Snapchat")) wpm.loadSnapchatPixel()
	}
})

// AddToCart event
jQuery(document).on("pmw:s2s:add-to-cart", (event, payload) => {

	const product = payload.product

	try {
		if (wpm.canNotFireSnapchatEvent()) return

		let data = {
			client_dedup_id: payload.snapchat.event_id,
			currency       : product.currency,
			price          : product.price * product.quantity,
			number_items   : product.quantity,
			item_ids       : [wpm.getProductIdForSpecificPixel(product, "snapchat")],
		}

		snaptr("track", "ADD_CART", data)
		pmw.console.log("Snapchat Pixel: ADD_CART event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// VIEW_CONTENT event
jQuery(document).on("pmw:s2s:view-item", (event, payload) => {

	const product = payload.product

	try {
		if (wpm.canNotFireSnapchatEvent()) return

		let data = {
			client_dedup_id: payload.snapchat.event_id,
		}

		if (product) {
			data.currency     = product.currency
			data.price        = product.price * product.quantity
			data.number_items = product.quantity
			data.item_ids     = [wpm.getProductIdForSpecificPixel(product, "snapchat")]
		}

		snaptr("track", "VIEW_CONTENT", data)
		pmw.console.log("Snapchat Pixel: VIEW_CONTENT event sent", data)

	} catch (e) {
		console.error(e)
	}
})

// SEARCH event
jQuery(document).on("pmw:s2s:search", (event, payload) => {

	try {
		if (wpm.canNotFireSnapchatEvent()) return

		let data = {
			client_dedup_id: payload.snapchat.event_id,
			search_string  : payload.snapchat.custom_data.search_string,
		}

		snaptr("track", "SEARCH", data)
		pmw.console.log("Snapchat Pixel: SEARCH event sent")

	} catch (e) {
		console.error(e)
	}
})

// ADD_TO_WISHLIST event
jQuery(document).on("pmw:s2s:add-to-wishlist", (event, payload) => {

	const product = payload.product

	try {
		if (wpm.canNotFireSnapchatEvent()) return

		let data = {
			client_dedup_id: payload.snapchat.event_id,
			currency       : product.currency,
			price          : product.price * product.quantity,
			number_items   : product.quantity,
			item_ids       : [wpm.getProductIdForSpecificPixel(product, "snapchat")],
		}

		snaptr("track", "ADD_TO_WISHLIST")
		pmw.console.log("Snapchat Pixel: ADD_TO_WISHLIST event sent")

	} catch (e) {
		console.error(e)
	}
})

// LOGIN event
jQuery(document).on("pmw:s2s:login", (event, payload) => {

	try {
		if (wpm.canNotFireSnapchatEvent()) return

		let data = {
			client_dedup_id: payload.snapchat.event_id,
		}

		snaptr("track", "LOGIN", data)
		pmw.console.log("Snapchat Pixel: LOGIN event sent")

	} catch (e) {
		console.error(e)
	}
})

// START_CHECKOUT event
jQuery(document).on("pmw:s2s:begin-checkout", (event, payload) => {

	try {
		if (wpm.canNotFireSnapchatEvent()) return

		let data = {
			client_dedup_id: payload.snapchat.event_id,
		}

		snaptr("track", "START_CHECKOUT", data)
		pmw.console.log("Snapchat Pixel: START_CHECKOUT event sent")

	} catch (e) {
		console.error(e)
	}
})


// PURCHASE event. View order received page event
jQuery(document).on("pmw:view-order-received-page", () => {

	try {
		if (wpm.canNotFireSnapchatEvent()) return
		if (!wpmDataLayer.order) return

		const data = {
			currency       : wpmDataLayer.order.currency,
			price          : wpmDataLayer.order.value.marketing,
			transaction_id : wpmDataLayer.order.id,
			item_ids       : wpm.getSnapchatOrderItemIds(),
			customer_status: wpmDataLayer.order.new_customer ? "new" : "returning",
		}

		snaptr("track", "PURCHASE", data)
		pmw.console.log("Snapchat Pixel: PURCHASE event sent", data)

	} catch (e) {
		console.error(e)
	}
})
