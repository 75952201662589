/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Run the following on every page load
 */

jQuery(document).on("pmw:load-always", function () {

	try {

	} catch (e) {
		console.error(e)
	}
})
