/**
 * Copyright 2024 SweetCode. All rights reserved.
 *
 * Load TikTok Ads functions
 * */

(function (wpm, $, undefined) {

	wpm.loadTikTokPixel = () => {

		try {
			wpmDataLayer.pixels.tiktok.loaded = true

			// @formatter:off
			!function (w, d, t) {
				w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

				ttq.load(wpmDataLayer.pixels.tiktok.pixel_id);
				ttq.page();
			}(window, document, 'ttq');
			// @formatter:on

			if (
				wpmDataLayer?.pixels?.tiktok?.advanced_matching
				&& (
					wpmDataLayer?.user?.email?.sha256
					|| wpmDataLayer?.user?.phone?.sha256?.e164
				)
			) {
				let identity = {}

				// https://ads.tiktok.com/marketing_api/docs?id=1739585700402178
				if (wpmDataLayer?.user?.email?.sha256) identity.sha256_email = wpmDataLayer.user.email.sha256
				if (wpmDataLayer?.user?.phone?.sha256?.e164) identity.sha256_phone_number = wpmDataLayer.user.phone.sha256.e164

				ttq.identify(identity)
			}

		} catch (e) {
			console.error(e)
		}
	}

	wpm.getTikTokOrderItemIds = () => {

		let orderItems = []

		Object.values(wpmDataLayer.order.items).forEach((item) => {

			let orderItem

			orderItem = {
				quantity: item.quantity,
				price   : item.price,
			}

			let itemId             = wpmDataLayer?.shop?.variations_output && 0 !== item.variation_id ? item.variation_id : item.id
			let product            = wpmDataLayer.products[itemId]
			orderItem.content_id   = wpm.getProductIdForSpecificPixel(product, "tiktok")
			orderItem.content_name = wpmDataLayer.products[itemId].name

			orderItems.push(orderItem)
		})

		return orderItems
	}

	wpm.tiktokGetEventUserData = () => {

		let user = {}

		let ttp = wpm.getCookie("_ttp")
		if (wpm.isValidTtp(ttp)) {
			user.ttp = ttp
		}

		let ttclid = wpm.getCookie("_ttclid") || wpm.retrieveData("ttclid")
		if (wpm.isValidTtclid(ttclid)) {
			user.ttclid = ttclid
		}

		if (wpmDataLayer?.pixels?.tiktok?.advanced_matching) {
			if (wpmDataLayer?.user?.email?.tiktok) user.email = wpmDataLayer.user.email.tiktok
			if (wpmDataLayer?.user?.phone?.tiktok) user.phone = wpmDataLayer.user.phone.tiktok
			if (wpmDataLayer?.user?.id?.sha256) user.external_id = wpmDataLayer.user.id.sha256
			if (navigator.userAgent) user.user_agent = navigator.userAgent
		}

		// get the BCP 47 language identifier from the browser and add it to the user data
		let locale = navigator.language || navigator.userLanguage
		if (locale) {
			user.locale = locale
		}

		return user
	}

	wpm.tiktokGetEventPageData = () => {

		let data = {
			url: window.location.href.split("?")[0],
		}

		if (wpm.retrieveData("referrer")) {
			data.referrer = wpm.makeFullUrl(wpm.retrieveData("referrer"))
		}

		return data
	}

	wpm.isValidTtp = ttp => {

		let re = new RegExp(/^[\da-zA-Z-]{20,50}$/)

		return re.test(ttp)
	}

	wpm.isValidTtclid = ttclid => {

		let re = new RegExp(/^[\da-zA-z-]{5,600}$/)

		return re.test(ttclid)
	}

	wpm.tiktokGetPropertiesFromCart = () => {

		let properties = {
			currency    : wpmDataLayer.shop.currency,
			value       : 0,
			contents    : [],
			content_type: "product",
		}

		Object.values(wpmDataLayer.cart).forEach((item) => {

			let content = {
				quantity: item.quantity,
				price   : item.price,
			}

			properties.value += item.price * item.quantity

			let dyn_r_ids = (!wpmDataLayer?.shop?.variations_output && item.is_variation && item.id !== 0) ? item.parent_id_dyn_r_ids : item.dyn_r_ids

			content.content_id   = String(dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type])
			content.content_name = item.name

			properties.contents.push(content)
		})

		return properties
	}

	/**
	 * Check if the TikTok pixel can be fired
	 *
	 * @return {boolean}
	 */
	wpm.canFireTikTokEvent = () => {

		if (!wpmDataLayer?.pixels?.tiktok?.loaded) return false

		// If the user has not given consent for marketing, return false
		if (!wpm.consent.categories.get().marketing) return false

		return true
	}

	/**
	 * Check if the TikTok pixel can not be fired
	 *
	 * @return {boolean}
	 */
	wpm.canNotFireTikTokEvent = () => {
		return !wpm.canFireTikTokEvent()
	}

}(window.wpm = window.wpm || {}, jQuery));
